import { Home } from "lucide-react";

import { CreateProjectDialog } from "@/components/create-project-dialog";
import { ProjectList } from "@/components/project-list";
import { SiteBreadcrumb } from "@/components/site-breadcrumb";
import { Button } from "@/components/ui/button";
import { useCreateProjectDialogStore } from "@/hooks/stores/create-project-dialog-store";

import { Layout, TopBar } from "./layout";

export default function Projects() {
  const { open } = useCreateProjectDialogStore();

  return (
    <Layout>
      <TopBar>
        <SiteBreadcrumb
          items={[
            {
              title: "Projects",
            },
          ]}
        />
        <div className="flex items-center gap-2">
          <Button size="sm" variant="default" onClick={open}>
            Create Project
          </Button>
          <CreateProjectDialog />
        </div>
      </TopBar>
      <div className="border border-border/75 flex flex-1 flex-col gap-4 pt-0 relative rounded-lg overflow-hidden bg-table">
        <div className="h-auto">
          <ProjectList />
        </div>
      </div>
    </Layout>
  );
}
