import { Eye, EyeOff, Filter, Settings2 } from "lucide-react";
import { useEffect } from "react";
import { create } from "zustand";

import CanisterTable from "@/components/canister-list";
import { ColumnVisibility } from "@/components/canister-list/display";
import { CanisterListFilters } from "@/components/canister-list/filters";
import { AggregateBurnChart } from "@/components/chart/aggregate-burn";
import { AggregateTopupChart } from "@/components/chart/aggregate-topup";
import { CanisterHealthChart } from "@/components/chart/canister-health";
import { SiteBreadcrumb } from "@/components/site-breadcrumb";
import { Button } from "@/components/ui/button";
import { PageActions } from "@/components/ui/page-actions";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { AppLink } from "@/hooks/queries/team";
import { useCanisterTable } from "@/hooks/stores/canister-table-store";
import { cn } from "@/lib/ui-utils";

import { Layout, TopBar } from "./layout";

interface SummaryVisibilityState {
  isVisible: boolean;
  toggle: () => void;
}

const useSummaryVisibility = create<SummaryVisibilityState>((set) => ({
  isVisible: localStorage.getItem("summaryVisible") !== "false",
  toggle: () =>
    set((state) => {
      const newValue = !state.isVisible;
      localStorage.setItem("summaryVisible", String(newValue));
      return { isVisible: newValue };
    }),
}));

function Summary() {
  const { isVisible, toggle } = useSummaryVisibility();

  return (
    <>
      <div className="hidden md:block">
        <Button
          size="sm"
          variant="outline"
          className="gap-2 bg-muted/0 text-xs"
          onClick={toggle}
        >
          {isVisible ? (
            <>
              <EyeOff className="w-3.5 h-3.5" /> Summary
            </>
          ) : (
            <>
              <Eye className="w-3.5 h-3.5" /> Summary
            </>
          )}
        </Button>
      </div>
      <div className="md:hidden">
        <Sheet>
          <SheetContent>
            <SummaryCharts />
          </SheetContent>
          <SheetTrigger>
            <Button
              variant="outline"
              size="sm"
              className="gap-2 bg-muted/0 text-xs"
            >
              <Eye className="w-3.5 h-3.5" /> Summary
            </Button>
          </SheetTrigger>
        </Sheet>
      </div>
    </>
  );
}

function SummaryCharts({ className }: { className?: string }) {
  const { isVisible } = useSummaryVisibility();

  return (
    <div
      className={cn(
        "flex flex-col md:flex-row gap-4 pb-4",
        className,
        !isVisible && "md:hidden"
      )}
    >
      <CanisterHealthChart className="flex-1" />
      <AggregateTopupChart className="flex-1" />
      <AggregateBurnChart className="flex-1" />
    </div>
  );
}

function CanistersTopBar() {
  const table = useCanisterTable();

  return (
    <TopBar>
      <div className="flex items-center gap-6 max-md:w-full">
        <SiteBreadcrumb
          items={[
            {
              title: (
                <>
                  Canisters{" "}
                  <span className="text-muted-foreground text-sm">
                    ({table.getRowCount()})
                  </span>
                </>
              ),
            },
          ]}
        />
        <div className="md:flex hidden">
          <CanisterListFilters>
            <Button size="sm" variant="outline" className="gap-2 text-xs">
              <Filter className="w-3.5 h-3.5" /> Filter
            </Button>
          </CanisterListFilters>
        </div>
      </div>
      <div className="flex items-center gap-2">
        <div className="md:flex hidden items-center gap-2">
          <ColumnVisibility>
            <Button size="sm" variant="outline" className="gap-2 text-xs">
              <Settings2 className="w-3.5 h-3.5" /> Display
            </Button>
          </ColumnVisibility>
        </div>
        <div className="md:flex hidden">
          <Summary />
        </div>
        <div className="md:flex hidden items-center gap-2">
          <AppLink className="plain" to={"canisters/new"}>
            <Button size="sm" variant="default">
              Add Canister
            </Button>
          </AppLink>
        </div>
        <PageActions>
          <CanisterListFilters>
            <Button
              size="sm"
              variant="outline"
              className="w-full justify-start gap-2"
            >
              <Filter className="w-4 h-4" /> Filter Canisters
            </Button>
          </CanisterListFilters>
          <Summary />
          <ColumnVisibility>
            <Button
              size="sm"
              variant="outline"
              className="w-full justify-start gap-2"
            >
              <Settings2 className="w-4 h-4" /> Display Options
            </Button>
          </ColumnVisibility>
          <AppLink className="plain" to={"canisters/new"}>
            <Button
              size="sm"
              variant="default"
              className="w-full justify-start"
            >
              Add Canister
            </Button>
          </AppLink>
        </PageActions>
      </div>
    </TopBar>
  );
}

export default function Page() {
  return (
    <Layout>
      <CanistersTopBar />
      <SummaryCharts className="hidden md:flex" />
      <div className="border border-border/75 flex flex-1 flex-col gap-4 p-2 md:p-4 pt-0 relative bg-background rounded-lg overflow-hidden">
        <CanisterTable className="h-auto absolute inset-0" />
      </div>
    </Layout>
  );
}
