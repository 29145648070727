import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Sidebar,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  useSidebar,
} from "@/components/ui/sidebar";
import { AppLink, useRoute } from "@/hooks/queries/team";
import { useBreakpoint } from "@/hooks/use-breakpoint";
import { cn } from "@/lib/ui-utils";

interface SidebarItem {
  label: string;
  path: () => string;
}

interface ResponsiveSidebarProps {
  items: SidebarItem[];
  className?: string;
}

function MobileSelect({ items }: { items: SidebarItem[] }) {
  const location = useLocation();
  const navigate = useNavigate();
  const currentItem = items.find((item) => item.path() === location.pathname);

  return (
    <div className="w-full mb-4">
      <Select
        value={currentItem?.path()}
        onValueChange={(value) => {
          const item = items.find((i) => i.path() === value);
          if (!item) return;
          navigate(item.path());
        }}
      >
        <SelectTrigger className="w-full bg-muted/50 border-border/50">
          <SelectValue placeholder={currentItem?.label || "Select page"} />
        </SelectTrigger>
        <SelectContent>
          {items.map((item) => (
            <SelectItem
              key={item.path()}
              value={item.path()}
              className="cursor-pointer"
            >
              {item.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
}

function DesktopSidebar({ items, className }: ResponsiveSidebarProps) {
  const location = useLocation();

  return (
    <div className={cn("mr-4", className)}>
      <Sidebar
        collapsible="none"
        className="w-64 border-r border-border/50 sticky top-2 h-auto p-2 pr-4 bg-transparent"
      >
        <SidebarMenu>
          {items.map((item) => (
            <SidebarMenuItem key={item.label}>
              <AppLink to={item.path()}>
                <SidebarMenuButton
                  className={cn(
                    "bg-muted/0 transition-colors duration-100",
                    item.path() === location.pathname &&
                      "bg-muted/50 border border-border/50",
                    "hover:bg-muted"
                  )}
                >
                  {item.label}
                </SidebarMenuButton>
              </AppLink>
            </SidebarMenuItem>
          ))}
        </SidebarMenu>
      </Sidebar>
    </div>
  );
}

export function ConditionalResponsiveSidebar({
  items,
  className,
}: ResponsiveSidebarProps) {
  const { state } = useSidebar();
  const isMobile = useBreakpoint();
  if (state === "expanded" && !isMobile) return null;
  return <ResponsiveSidebar items={items} className={className} />;
}

export function ResponsiveSidebar({
  items,
  className,
}: ResponsiveSidebarProps) {
  const isMobile = useBreakpoint(1024);
  if (isMobile) return <MobileSelect items={items} />;
  return <DesktopSidebar items={items} className={className} />;
}
