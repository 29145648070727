import { useLocation, useParams } from "react-router-dom";

import CanisterRule from "@/components/canister-rule";
import {
  CanisterAdvancedSettingsContent,
  CanisterAlertCard,
  CanisterSettingsContent,
} from "@/components/pages/canister-detail";
import { ResponsiveSidebar } from "@/components/ui/responsive-sidebar";
import { useRoute } from "@/hooks/queries/team";

import CanisterOrganizationPage from "./canister-organization";

export default function CanisterSettingsPage() {
  return (
    <CanisterSettingsLayout>
      <CanisterSettingsContent />
    </CanisterSettingsLayout>
  );
}

export function CanisterRulePage() {
  return (
    <CanisterSettingsLayout>
      <CanisterRule />
    </CanisterSettingsLayout>
  );
}

export function CanisterAlertPage() {
  return (
    <CanisterSettingsLayout>
      <CanisterAlertCard />
    </CanisterSettingsLayout>
  );
}

export function CanisterAdvancedPage() {
  return (
    <CanisterSettingsLayout>
      <CanisterAdvancedSettingsContent />
    </CanisterSettingsLayout>
  );
}

export { CanisterOrganizationPage };

export function CanisterSettingsLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <>
      <div className="flex flex-col lg:flex-row bg-table relative p-4 lg:p-2 min-h-full">
        <CanisterSettingsMenu />
        <div className="flex-1 lg:p-2 lg:pl-0">{children}</div>
      </div>
    </>
  );
}

const menuItems = (route: (path: string) => string, id?: string) => [
  {
    label: "General",
    path: () => route(`/canisters/detail/${id}/settings`),
  },
  {
    label: "Organization",
    path: () => route(`/canisters/detail/${id}/settings/organization`),
  },
  {
    label: "Top-Up Automation",
    path: () => route(`/canisters/detail/${id}/settings/rule`),
  },
  {
    label: "Alerts",
    path: () => route(`/canisters/detail/${id}/settings/alerts`),
  },
  {
    label: "Advanced",
    path: () => route(`/canisters/detail/${id}/settings/advanced`),
  },
];

function CanisterSettingsMenu() {
  const { canisterId: canisterIdString } = useParams();
  const route = useRoute();
  return <ResponsiveSidebar items={menuItems(route, canisterIdString)} />;
}
